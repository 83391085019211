<template>
  <div v-if="orders" class="orders-table">
    <div class="card-header search justify-content-start">
      <custom-select-2
          v-model="searchParams.searchStatus"
          name="status"
          label="Статус"
          :options="statusOptions"
      />
      <custom-select-2
          v-show="getRight('allOrders')"
          v-model="searchParams.searchUser"
          name="user"
          label="Пользователь"
          :settings="usersAjax"
      />
      <custom-input
          label="Email"
          name="email"
          v-model="searchParams.searchEmail"
          cleanable
      />
    </div>
    <div class="card-header search justify-content-start">
      <custom-input
          :delay="400"
          label="Цена от"
          type="number"
          name="priceFrom"
          v-model="searchParams.searchPrice"
      />
      <custom-select-2
          v-show="getRight('allOrders')"
          v-model="searchParams.searchCompany"
          name="company"
          label="Компания"
          :options="['Не выбрано', ...organizationsOptions]"
      />
    </div>
    <div class="card-header search justify-content-start products-search">
      <custom-select-2
          v-show="getRight('allOrders')"
          v-model="searchParams.searchProducts"
          name="products"
          label="Товары"
          :multiple="true"
          :options="productsOptions"
          :labelWidth="12"
          :inputWidth="88"
      />
    </div>
    <div class="card-header search search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
    <div class="card-header additional-info justify-content-between">
      <div v-if="shownData.length" class="select-count justify-self-end">
        <custom-select
            :labelWidth="40"
            name="rowsCount"
            v-model="countOnPage"
            label="Кол-во заказов на странице"
            :options="[10, 15, 20, 50, 75]"
        />
      </div>
      <div>
        <transition name="fade">
          <div v-if="checkedList.length" class="selected-count">
            Выделено: {{ checkedList.length }}
          </div>
        </transition>
      </div>
    </div>

    <div v-if="shownData.length">
      <base-table
          :columns="ordersFields"
          :rows="shownData"
          :rowSelect=onRowSelected
          :checkedList=checkedList
          @sort="(data) => {
          sortValue = data.sortValue;
          sortDirection = data.direction;
        }"
      />

      <table-pagination
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div class="table-preloader" v-else>
    <preloader/>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import {useStore} from "vuex";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import useCheckRights from "@/mixins/useCheckRights";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";

export default {
  name: "orders-table",
  components: {
    BaseTable,
    CustomSelect2,
    VPagination,
    CustomSelect,
    Preloader,
    CustomInput,
    TablePagination,
  },
  setup() {
    const store = useStore(),
        {getRight} = useCheckRights();

    const countOnPage = ref(parseInt(localStorage.getItem("countOnPageOrders")) || 10),
        searchParams = reactive({
          searchStatus: "Не выбрано",
          searchCompany: "Не выбрано",
          searchUser: "Не выбрано",
          searchPrice: null,
          searchEmail: "",
          searchProducts: [],
        }),
        checkedList = ref([]),
        orderStatuses = {
          new: "Новый",
          sent: "Отправлен",
          paid: "Оплачен",
        },
        statusOptions = ["Не выбрано", "Новый", "Оплачен", "Отправлен"],
        activeFilter = ref(false),
        organizationsOptions = ref([]);
    const productsOptions = ref([]);
    const orders = computed(() => store.state.relations.orders),
        organizations = computed(() => store.state.company.companies),
        products = computed(() => store.state.relations.productsList),
        rowData = computed(() => {
          return orders.value.map((order) => {
            return {
              id: order.id,
              order_id: order.order_id,
              price: order.price,
              status: orderStatuses[order.status],
              products: order.products,
              company:
                  order.user && order.user.company ? order.user.company.name : "",
              user: order.user
                  ? `${order.user.last_name} ${order.user.first_name} ${order.user.patronymic}`
                  : "",
              paid_at: order.paid_at ? order.paid_at.slice(0, 10) : "-",
              created_at: order.created_at ? order.created_at.slice(0, 10) : "-",
              email: order.user ? order.user.email : "",
              entities: order.entities,
              user_id: order.user
            };
          });
        });
    const {usersAjax} = useAjaxSelect(true);

    const ordersFields = [
      {
        field: "check",
        headerName: "",
        checkAll: true,
      },
      {
        field: "price",
        headerName: "Цена",
      },
      {
        field: "order_status",
        headerName: "Статус",
      },
      {
        field: "products",
        headerName: "Товары",
      },
      {
        field: "user",
        headerName: "Пользователь",
      },
      {
        field: "email",
        headerName: "Email",
      },
      {
        field: "company",
        headerName: "Компания",
      },
      {
        field: "paid_at",
        headerName: "Время оплаты",
        sortable: true,
      },
      {
        field: "created_at",
        headerName: "Создан",
        sortable: true,
      },
      {
        field: "order_user_id",
        headerName: "",
      },
    ];

    const onRowSelected = (id, selectAll) => {
      const isInclude = checkedList.value.includes(id);
      if (id && isInclude) {

        if (!selectAll) {
          checkedList.value = checkedList.value.filter(
              (currentId) => currentId !== id
          );
        }
      } else {
        checkedList.value.push(id);
      }
    };

    const clearAllSearch = () => {
      searchParams.searchStatus = "Не выбрано";
      searchParams.searchCompany = "Не выбрано";
      searchParams.searchUser = "Не выбрано";
      searchParams.searchEmail = "";
      searchParams.searchProducts = [];
      searchParams.searchPrice = null;
    };

    const pageDataStart = ref(0);
    const sortValue = ref('');
    const sortDirection = ref('');
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.searchStatus !== 'Не выбрано') {
          data = data.filter((el) => el.status === searchParams.searchStatus);
        }

        if (searchParams.searchUser !== 'Не выбрано') {
          data = data.filter((el) =>
              el.user === searchParams.searchUser
          );
        }

        if (searchParams.searchCompany !== 'Не выбрано') {
          data = data.filter((el) =>
              el.company === searchParams.searchCompany
          );
        }

        if (searchParams.searchEmail) {
          data = data.filter((el) =>
              el.email.toLowerCase().includes(searchParams.searchEmail.toLowerCase())
          );
        }

        if (searchParams.searchPrice) {
          data = data.filter((el) => parseInt(el.price) >= parseInt(searchParams.searchPrice));
        }

        if (searchParams.searchProducts && searchParams.searchProducts.length) {
          let selectedProducts = Object.values(searchParams.searchProducts);
          data = data.filter((el) =>
              el.products.some((product) => selectedProducts.includes(product.name))
          );
        }

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'created_at', 'down');
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("relations/getOrders");
      store.dispatch("company/getCompanies").then(() => {
        organizationsOptions.value = organizations.value.map(
            (company) => company.name
        );
      });
      store.dispatch("relations/getProductsList").then(() => {
        const filterSet = new Set();
        products.value.forEach((item) => filterSet.add(item.name));
        productsOptions.value = Array.from(filterSet);
      });
    });

    watch(countOnPage, () => {
      localStorage.setItem("countOnPageOrders", countOnPage.value);
    });

    watch(
        () => [sortDirection.value, sortValue.value, countOnPage.value,
          searchParams.searchStatus, searchParams.searchUser, searchParams.searchEmail,
          searchParams.searchCompany, searchParams.searchPrice, searchParams.searchProducts],
        () => {
          totalResults.value = 0;
          countOnPage.value = parseInt(countOnPage.value);
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      countOnPage,
      checkedList,
      statusOptions,
      productsOptions,
      searchParams,
      activeFilter,
      clearAllSearch,
      orders,
      organizationsOptions,
      usersAjax,

      getRight,

      rowData,
      onRowSelected,

      shownData,
      pageDataStart,
      sortValue,
      sortDirection,
      ordersFields,
      changePage,
      updatePagination,
      totalResults
    };
  },
};
</script>

<style lang="scss">
.orders-table {
  .ag-body-viewport {
    min-height: 250px;
  }
}
</style>

<style lang="scss" scoped>
.checkbox-cell {
  padding-bottom: 4px;
}

.card-header {
  flex-wrap: wrap;
}

.no_results {
  padding: 40px 10px;
}

.orders-table {
  .card-header {
    margin: 0;
    padding: 10px 0;
  }

  .select-count {
    min-width: 345px;
  }

  .search__clear-btn {
    padding-left: 10px;
  }

  @media (min-width: 1550px) {
    .card-header {
      .form-group:not(:last-child) {
        margin-right: 15px;
      }

      .form-group {
        min-width: 445px;
      }
    }

    .products-search {
      .form-group {
        min-width: 900px;
      }
    }
  }

  @media (max-width: 1550px) {
    .head-sort {
      flex-direction: column;
      align-items: flex-start;
    }
    .reset-btn {
      margin-top: 15px;
      margin-left: 0;
    }

    .search {
      display: flex;
      flex-direction: column;

      .form-group {
        width: 100%;
        max-width: 100%;
      }

      &__clear-btn {
        padding: 0;
        align-items: flex-end;
        padding-right: 15px;
      }
    }

    .select-count {
      min-width: 500px;
    }
  }

  @media (max-width: 978px) {
    .card-header {
      padding: 2px 0;
    }
    .search {
      &__clear-btn {
        padding-right: 10px;
        margin-top: 8px;
      }
    }
    .additional-info {
      flex-direction: column;
      align-items: flex-start;
    }
    .select-count {
      min-width: 100%;
      padding-left: 0;
    }

    .selected-count {
      margin-top: 10px;
    }
  }
}
</style>
